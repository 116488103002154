.App {
  text-align: center;
}

.wrapper{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.router-view{
  flex: 1;
  width: 100vw;
  overflow-y: scroll;
  background: #e6e6e6;
}

.bottom-bar{
  left: 0;
  bottom: 0;
  z-index: 11111;
  height: 64px;
  width: 100vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.normal-title {
  font-size: 20px;
  font-weight: 400;
  color: #23231F;
  padding: 12px 16px;
  text-align: center;
}
.theme-red {
  color: #D00011;
}
.theme-bg-red{
  background: #D00011;
}
.theme-bg-gray {
  background: #808080;
}
.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper .content {
  width: 64vw;
  height: 64vw;
}
.loading-wrapper .content .loading-1{
  width: 64vw;
  height: 64vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-wrapper .content .loading-1 img {
  width: 20%;
  height: auto;
  animation: roleAmt 1s linear infinite;
}
.loading-wrapper .content .loading-2 {
  width: 64vw;
  height: 64vw;
  /* animation: logoAmt 4s linear infinite; */
}
.loading-wrapper .logo {
  bottom: 5vh;
}
@keyframes logoAmt {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes roleAmt {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}