.grid-floor {
    width: 40px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.grid-room {
    width: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.grid-poz {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.border-3-side {
    border: 1px solid #f1f1f1;
    border-right: none;
    border-bottom: none;
}

.border-right {
    border: 1px solid #f1f1f1;
    border-bottom: none;
}

.border-bottom {
    border-bottom: 1px solid #f1f1f1;
}